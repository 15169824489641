<template>
  <div class="jsmb-mon-compte">

    <div class="container is-fullhd">

      <div class="jsmb-container-title">
        <h1 class="jsmb-title-account is-size-3">Politique d'utilisation</h1>
        <Separateur class="jsmb-account-separateur" color="primary"/>
      </div>

      <div class="columns is-desktop">
        <div class="column has-text-centered-tablet has-text-centered-mobile jsmb-switch-rwd">
          <div :class="[displayOnTouch ? 'dropdown' : 'panel', dropDownOpen ? 'is-active' : '']" @click="clickDrop"> 

            <div class="dropdown-trigger" v-if="displayOnTouch">
              <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                <span>Choisir un élément</span>
                <span class="icon is-small">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </div>

            <div :class="{ 'dropdown-menu ' : displayOnTouch }" role="menu">
              <div :class="{ 'dropdown-content control is-expanded' : displayOnTouch }">
                <router-link :to="{name: 'Cgv'}" tag="a" :class="[$route.name == 'Cgv' ? 'is-active' : '', displayOnTouch ? 'dropdown-item': 'panel-block']" v-on:click.native="currentTab = 0" replace>CGV</router-link>
                <!-- <router-link :to="{name: 'Cgu'}" tag="a" :class="[$route.name == 'Cgu' ? 'is-active' : '', displayOnTouch ? 'dropdown-item': 'panel-block']" v-on:click.native="currentTab = 1" replace>CGU</router-link> -->
                <router-link :to="{name: 'Mentionslegales'}" tag="a" :class="[$route.name == 'Mentionslegales' ? 'is-active' : '', displayOnTouch ? 'dropdown-item': 'panel-block']" v-on:click.native="currentTab = 2" replace>MENTIONS LÉGALES</router-link>
                <router-link :to="{name: 'Confidentiality'}" tag="a" :class="[$route.name == 'Confidentiality' ? 'is-active' : '', displayOnTouch ? 'dropdown-item': 'panel-block']" v-on:click.native="currentTab = 3" replace>POLITIQUE DE CONFIDENTIALITÉ</router-link>
                <router-link :to="{name: 'DatasPolitics'}" tag="a" :class="[$route.name == 'DatasPolitics' ? 'is-active' : '', displayOnTouch ? 'dropdown-item': 'panel-block']" v-on:click.native="currentTab = 4" replace>POLITIQUE DE GESTION DES DONNÉES</router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-9-desktop is-12-mobile">
          <router-view :class="this.$router.name=='Message' ? 'jsmb-account-comments' : 'jsmb-account-information'"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Separateur from '@/components/template/ui/Separator.vue'

export default {
  name: 'Legal',
  components: {
    Separateur
  },
  data () {
    return {
      currentTab: 0,
      dropDownOpen:false
    }
  },
  methods: {
    clickDrop(){
      this.dropDownOpen = this.dropDownOpen ? false : true;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">
  .jsmb-mon-compte {
    overflow: hidden;
  }
  .panel-block:first-child {
    border-top:none;
  }
  @media screen and (max-width:1049px) {
    .jsmb-switch-rwd {
      background: #f2f5fb;
      padding:2rem 5rem 1rem;
    }
  }
  @media screen and (max-width:578px) {
    .jsmb-switch-rwd {
      padding:2rem;
    }
  }
  .jsmb-container-title {
    padding:2rem 2rem 2rem 0.5rem;
  }
  .jsmb-title-account {
    font-weight: 100;
    text-transform: uppercase;
  }
  .jsmb-account-separateur {
    width:35px;
  }
  .panel-block {
    background:#fff;
    border:none;
    padding:2rem 0.5rem 2rem 0.5rem;
    text-transform: uppercase;
    color:#818080;
    font-size:0.9rem;
    font-weight: bold;
    position: relative;
  }
  .panel-block.is-active,
  .panel-block:hover {
    background: #f2f5fb;
  }
  .panel-block:before {
    content:"";
    background: #fff;
    padding:2rem 0.5rem 2rem 0.5rem;
    position:absolute;
    left:-100%;
    display: block;
    width: 100%;
    height: inherit;
  }
  .panel-block.is-active:before,
  .panel-block:hover:before {
    content:"";
    background: #f2f5fb;
    padding:2rem 0.5rem 2rem 0.5rem;
    position:absolute;
    left:-200%;
    display: block;
    width: 200%;
    height: 100%;
  }
  .panel-block:after {
    content: "";
    border-bottom: 1px solid #eeeeee;
    display: block;
    position: absolute;
    bottom: 0;
    z-index: 40;
    width: 90%;
  }
  .jsmb-account-information {
    padding:5rem;
    background: #f2f5fb;
    position:relative;
  }
  .jsmb-account-comments {
    padding: 0 5rem 5rem 5rem;
    background: #f2f5fb;
    position:relative;
  }
  .jsmb-account-comments:after,
  .jsmb-account-information:after {
    content: "";
    position: absolute;
    right: -100%;
    background: #f2f5fb;
    width: 100%;
    height: 100%;
    top: 0;
  }
  @media screen and (max-width:577px) {
    .jsmb-account-information {
      padding:2rem;
    }
  }

  /* fenetre détail */
  .jsmb-title-detail {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }
  .field {
        margin-bottom: 0.5rem;
  }
  .label {
    font-weight: 100
  }
</style>
